import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { Video } from 'src/components/atoms/Video';

export default function Drawer({
  heading,
  content,
  contentNode,
  ctas,
  customData,
  video
}) {
  const [myElementIsVisible, updateMyElementIsVisible] = useState(false);
  const videoRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
  }, [])
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {video && (
          <div ref={videoRef}>
            <Video
              playing={myElementIsVisible}
              src={video.url}
              width="900"
              height="62.4%"
              autoPlay
              muted
              playsInline
              controls
              loop
              shadow
              round
            />
          </div>
        )}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
